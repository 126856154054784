// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';
import { notification } from 'antd';

/** 获取当前的用户 GET /api/current_user */
export async function currentUser(options?: { [key: string]: any }) {
  const response = await request<{
    data: API.CurrentUser;
  }>('/api/user/current_user', {
    method: 'GET',
    ...(options || {}),
  });
  return response.data
}

export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/loginout', {
    method: 'POST',
    ...(options || {}),
  });
}

export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  const response = await request<API.LoginResult>('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
  if (response.retcode !== 0) {
    throw new Error(response.message || 'An error occurred');
  }
  return response
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('/api/rule', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),
    },
  });
}

export async function visitRecordSearch(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    /** 日期范围 */
    date_range?: [number, number];
    [key: string]: any; // 支持其他额外的搜索参数
  },
  options?: { [key: string]: any },
) {
  const { date_range, current, pageSize, ...restParams } = params;
  const processedParams = {
    ...restParams,
    pageno: current || 1,
    count: pageSize || 20,
    ...(date_range && date_range.length === 2
      ? {
        ...(date_range[0] ? { begin_ctime: Math.floor(date_range[0] / 1000) } : {}),
        ...(date_range[1] ? { end_ctime: Math.floor(date_range[1] / 1000) } : {}),
      }
      : {}),
  };
  const response = await request<API.VisitRecordList>('/api/visit_record/search', {
    method: 'GET',
    params: processedParams,
    ...(options || {}),
  });
  if (response.retcode !== 0) {
    notification.error({
      message: '请求失败',
      description: response.message || '发生未知错误',
    });
    throw new Error(response.message || 'An error occurred');
  }

  return {
    data: response.data.list,
    total: response.data.total,
    pageSize: response.data.count,
    success: response.retcode === 0,
    message: response.message,
  };
}

export async function UserSearch(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  // const mappedParams = {
  //   pageno: params.current || 1,
  //   count: params.pageSize || 10,
  // };

  const response = await request<API.VisitRecordList>('/api/user/search', {
    method: 'GET',
    params: {
      pageno: params.current || 1,
      count: params.pageSize || 20,
      ...params,
    },
    ...(options || {}),
  });
  if (response.retcode !== 0) {
      notification.error({
        message: '请求失败',
        description: response.message || '发生未知错误',
      });
      throw new Error(response.message || 'An error occurred');
  }

  return {
    data: response.data.list,
    total: response.data.total,
    current: response.data.pageno,
    pageSize: response.data.count,
    success: response.retcode === 0,
    message: response.message,
  };

}

export async function ProjectSearch(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  // const mappedParams = {
  //   pageno: params.current || 1,
  //   count: params.pageSize || 10,
  // };

  const response = await request<API.VisitRecordList>('/api/project/search', {
    method: 'GET',
    params: {
      pageno: params.current || 1,
      count: params.pageSize || 20,
      ...params,
    },
    ...(options || {}),
  });
  if (response.retcode !== 0) {
    notification.error({
      message: '请求失败',
      description: response.message || '发生未知错误',
    });
    throw new Error(response.message || 'An error occurred');
  }

  return {
    data: response.data.list,
    total: response.data.total,
    current: response.data.pageno,
    pageSize: response.data.count,
    success: response.retcode === 0,
    message: response.message,
  };

}

function get_response(response) {
  const response_data = {}
  if (response.retcode !== 0) {
    throw new Error(response.message || ' retcode: ' || response.retode);
  }
  return response_data
}

export async function createUserApi(options?: { [key: string]: any }) {
  const response = await request<API.UserCreateResultType>('/api/user/create', {
    method: 'POST',
    data: {
      ...(options || {}),
    },
  });
  if (response.retcode !== 0) {
    const errorMessage = response.message ? response.message : 'An error occurred';
    const errorCode = response.retcode !== undefined ? response.retcode : 'Unknown code';
    throw new Error(`${errorMessage} (retcode: ${errorCode})`);
  }

  const response_data = {
    "success": true,
  };
  return response_data
}

export async function createProjectApi(options?: { [key: string]: any }) {
  const response = await request<API.ProjectCreateResultType>('/api/project/create', {
    method: 'POST',
    data: {
      ...(options || {}),
    },
  });
  if (response.retcode !== 0) {
    const errorMessage = response.message ? response.message : 'An error occurred';
    const errorCode = response.retcode !== undefined ? response.retcode : 'Unknown code';
    throw new Error(`${errorMessage} (retcode: ${errorCode})`);
  }

  const response_data = {
    "success": true,
  };
  return response_data
}

export async function getMenus(options?: { [key: string]: any }) {
  const response = await request<API.MenuDataItem[]>('/api/user/current_user_menus', {
    method: 'GET',
    params: options,
  });

  return response.data;
}


export async function getWalletInfo(options?: { [key: string]: any }) {
  const response = await request<API.WalletInfo[]>('/api/wallet/wallet_detail', {
    method: 'GET',
    params: options,
  });

  return response.data;
}


export async function getWalletRecordList(options?: { [key: string]: any }) {
  const response = await request<API.WalletRecordList[]>('/api/wallet/wallet_record/search', {
    method: 'GET',
    params: options,
  });

  return {
    data: response.data.list,
    total: response.data.total,
    current: response.data.pageno,
    pageSize: response.data.count,
    success: response.retcode === 0,
    message: response.message,
  };
}


export async function getCloakWalletInfo(options?: { [key: string]: any }) {
  const response = await request<API.cloakWalletInfo[]>('/api/cloak_wallet/cloak_wallet_detail', {
    method: 'GET',
    params: options,
  });

  return response.data;
}


export async function getCloakWalletRecordList(options?: { [key: string]: any }) {
  const response = await request<API.CloakWalletRecordList[]>('/api/cloak_wallet/cloak_wallet_record/search', {
    method: 'GET',
    params: options,
  });

  return {
    data: response.data.list,
    total: response.data.total,
    current: response.data.pageno,
    pageSize: response.data.count,
    success: response.retcode === 0,
    message: response.message,
  };
}
