import React from 'react';
import { Button } from 'antd';
import { MessageFilled } from '@ant-design/icons';

const TelegramButton = () => {
  const handleClick = () => {
    window.open('https://telegram.me/lazaso', '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
      }}
    >
      <Button
        type="primary"
        shape="circle"
        icon={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MessageFilled
              style={{
                fontSize: '35px', // 图标大小
              }}
            />
          </div>
        }
        size="large"
        onClick={handleClick}
        style={{
          backgroundColor: '#0088cc',
          borderColor: '#0088cc',
          width: '65px', // 调整图片大小
          height: '65px', // 调整图片大小
        }}
      />
    </div>
  );
};

export default TelegramButton;
