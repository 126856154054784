// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import DashboardOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/DashboardOutlined';
import SafetyCertificateOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/SafetyCertificateOutlined';
import CloudOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/CloudOutlined';
import EyeOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/EyeOutlined';
import WalletOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/WalletOutlined';
import ProjectOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/ProjectOutlined';
import UserOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/UserOutlined';
import DollarOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/DollarOutlined';
import MoneyCollectOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/MoneyCollectOutlined';
import QuestionCircleOutlined from '/Volumes/reddisk/git_project/fe-admin-bss/node_modules/@ant-design/icons/es/icons/QuestionCircleOutlined';
export default { DashboardOutlined, SafetyCertificateOutlined, CloudOutlined, EyeOutlined, WalletOutlined, ProjectOutlined, UserOutlined, DollarOutlined, MoneyCollectOutlined, QuestionCircleOutlined };
